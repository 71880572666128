<template>
    <button @click.stop.prevent="doIt()" @click="handleClick" :aria-label="btn.ariaLabel" class="btn" :class="[customClass, 'btn-' + btn.type, { 'btn-no-label': btn.label == false }, { 'btn-loading': isLoading }]">
        {{ btn.label ? btn.label : '' }}
    </button>
</template>

<script>
export default {
    props: {
        bType: { type: String, default: 'normal' },
        bLabel: { type: [String, Boolean], default: '' },
        bCallback: { type: Function, default: function () {} },
        bCallbackProps: {
            type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
            default: undefined
        },
        customClass: { type: [String, Array, Object, Function], default: '' },
        isLoading: { type: Boolean, default: false },
        bTypeOfBtn: { type: String, default: 'button' }
    },
    data() {
        return {
            types: {
                cancel: { label: 'button.cancel', callback: function () {} },
            }
        }
    },
    computed: {
        btn() {
            var def = this.types[this.bType]
            var result = {
                type: this.bType ? this.bType : 'normal',
                label: this.bLabel === false ? false : this.bLabel != '' ? this.$t(this.bLabel) : this.$t(def.label),
                callback: this.bCallback ? this.bCallback : def.callback,
                props: this.bCallbackProps ? this.bCallbackProps : undefined,
                ariaLabel: def && def.ariaLabel ? this.$t(def.ariaLabel) : ''
            }
            return result
        }
    },
    methods: {
        doIt() {
            var self = this
            if (this.bType == 'save' && this.$store.getters['multilanguage/getTranslationLang']) {
                this.$nextTick(() => {
                    self.$store.dispatch('multilanguage/saveTranslations')
                })
            }

            if (!this.isLoading) {
                this.btn.callback(this.btn.props)
            }
        },
        handleClick() {
            this.$emit('on-button-click')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.btn {
    @include font-size(sm);
    @include background($color: $color-neutral-200, $size: 15px, $position: 11px center);
    @include box-shadow($h: 0px, $v: 0px, $b: 4px, $s: 0px, $c: rgba($color-neutral-600, 0.05));
    padding: 8px 9px 8px 35px;
    margin: 0 0 0 12px;
    height: auto;
    width: fit-content;
    font-family: $text-bold;
    color: $color-white;
    border-radius: 4px;
    line-height: initial;
    color: $color-white;
}

// Common styles

.btn-cancel{
    color: $color-black;
    background-color: $color-white;
    border: 1px solid $color-neutral-300;

    &:hover {
        background-color: $color-neutral-300;
        border: 1px solid $color-neutral-500;
    }

    &.disabled {
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }

    &.selector-popup{
        width: 100%;
        padding: 8px 9px;
        margin: 8px 0;
        color: $color-white;
        background-color: $main;
    }
}
</style>
